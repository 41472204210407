<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div v-if="!item.id" class="p-dialog-title">Adding a new warehouse</div>
      <div v-else class="p-dialog-title">Warehouse editing</div>
    </template>
    <div class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="warehouse_name" v-model.trim="warehouseData.name" autocomplete="off"/>
          <label for="warehouse_name">Name<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !warehouseData.name">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="warehouse_description" v-model.trim="warehouseData.description" autocomplete="off"/>
          <label for="warehouse_description">Description</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-float-label">
          <Textarea id="warehouse_address" v-model.trim="warehouseData.address" rows="2" :autoResize="true" autocomplete="off"/>
          <label for="warehouse_address">Address</label>
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";

export default {
  mixins: [ httpMixins ],
  emits: ['close', 'update-items'],
  name: 'WarehouseModal',
  props: {
    item: Object,
    visible: Boolean,
  },
  data() {
    return {
      warehouseData: {},
      submitted: false
    }
  },
  watch: {
    item() {
      this.warehouseData = { ...this.item }
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
      }
    },
  },
  methods: {
    async saveItem() {
      this.submitted = true
      if (!this.warehouseData.name) {
        return false
      }

      const warehouse = {
        id: this.warehouseData.id ?? null,
        name: this.warehouseData.name ? this.warehouseData.name : null,
        description: this.warehouseData.description ? this.warehouseData.description : null,
        address: this.warehouseData.address ? this.warehouseData.address : null,
      }

      if (this.warehouseData.id) {
        try {
          const { status } = await httpClient.post(`warehouse/update-warehouse`, warehouse)
          if (status === 200) {
            this.$emit('update-items', this.warehouseData.id)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: 1750});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`warehouse/create-warehouse`, warehouse)
          if (status === 201 && data) {
            this.warehouseData.id = data
            warehouse.id = data
            this.$emit('update-items', this.warehouseData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: 1750});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>